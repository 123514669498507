<template>
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id :value="model.id" label="id" disabled />
        <n-field-date :value="model.date" label="date" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.domain" field-name="domain" label="domain" :error="error.domain" disabled />
        <n-field-datetime v-model="model.time" field-name="time" label="time" :error="error.time" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-selection
          v-model="model.companyId"
          field-name="companyId"
          selection-name="company.company"
          label="company"
          :error="error.companyId"
          disabled
        />
        <n-field-text v-model="model.type" field-name="type" label="type" :error="error.type" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.sourceId" label="sourceId" disabled />
        <n-field-enum v-model="model.status" label="status" enum-name="IssueStatus" :error="error.status" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.contextId" label="contextId" disabled />
        <n-field-text v-model="model.comment" field-name="comment" label="comment" :error="error.comment" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import ModalMixin from '@/mixins/modal-mixin';
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalStatus',
  components: { NModal },
  mixins: [ModalMixin],
});
</script>
