<template>
  <index-content>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-enum v-model="condition.status" enum-name="IssueStatus" label="status" all-option />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
      <n-field-text v-model="condition.domain" label="domain" />
      <n-field-text v-model="condition.type" label="type" />
    </search-condition>
    <n-table
      :total="total"
      :page-no="pageNo"
      :page-size="pageSize"
      :searched="searched"
      :searching="searching"
      :has-result="hasResult"
      @on-page-no="onPageNo"
      @on-page-size="onPageSize"
    >
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="n-table-id">{{ $t('label.id') }}</th>
            <th class="company">{{ $t('label.company') }}</th>
            <th class="domain">{{ $t('label.domain') }}</th>
            <th class="type">{{ $t('label.type') }}</th>
            <th class="status">{{ $t('label.status') }}</th>
            <th class="n-table-date">{{ $t('label.date') }}</th>
            <th class="n-table-timestamp">{{ $t('label.time') }}</th>
            <th class="action-detail">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id | hyphen }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td>{{ row.domain | hyphen }}</td>
            <td>{{ row.type | hyphen }}</td>
            <td v-enums:IssueStatus="row.status"></td>
            <td>{{ row.date }}</td>
            <td>{{ row.time | datetime }}</td>
            <td class="n-button-box">
              <nb-custom type="info" text="detail" @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import SystemView from './view';
import ModalStatus from './ModalStatus.vue';
import { createModal } from '@/helpers/modal';
import { fetch } from '@/api/system/system-status';
import PaginateMixin from '@/mixins/paginate-mixin';

export default SystemView.extend({
  name: 'SystemAlert',
  mixins: [PaginateMixin],

  data() {
    return {
      condition: {
        type: '',
        domain: '',
        status: 0,
        companyId: 0,
        from: this.oneWeekAgo,
        to: this.eod,
      },
    };
  },

  methods: {
    doSearch(params) {
      return fetch({
        ...params,
        ...this.condition,
      });
    },

    openModal(model) {
      createModal(ModalStatus, { model, on: this });
    },
  },
});
</script>
<style lang="scss" scoped>
.n-table {
  min-width: 1024px;
  th.company {
    width: 10%;
  }
  th.status,
  th.domain {
    width: 10%;
  }
}
</style>
